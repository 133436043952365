<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">我的</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
        <span class="back">返回</span>
      </div>
      <div class="nav_right_btn">下一页</div>
    </div>
    <div class="mine-top">
      <div class="mine-mess">
        <div class="head" />
        <div>
          <div class="message">T2ulYf</div>
          <div class="message">中国工商银行权益用户</div>
        </div>
      </div>
      <div class="swiper-left" />
    </div>
    <div class="mine-top-second">
      <div
        v-for="(item, index) in playItems"
        :key="index"
        class="play-item"
        :class="{play_item_right:index === 0 || index === 1}"
        @click="toItems(item.function)"
      >
        <img :src="item.img" alt />
        <div class="play-title">{{ item.title }}</div>
      </div>
    </div>
    <div class="content">
      <div
        v-for="(item, index) in orderItems"
        :key="index"
        class="item-list"
        :style="{'border-bottom' : index === orderItems.length-1 ? 'none' : ''}"
      >
        <img :src="item.img" alt />
        <div class="play-title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import logo from "@/assets/logo.png";
import order from "@/assets/order.png";
import question from "@/assets/question.png";
import about from "@/assets/about.png";
import identify from "@/assets/identify.png";
import chargeIn from "@/assets/chargeIn.png";
import coupon from "@/assets/coupon.png";
export default {
  name: "Personal",
  data() {
    return {
      cancel,
      order,
      question,
      about,
      identify,
      coupon,
      playItems: [
        { title: "我的券", img: coupon, function: "" },
        { title: "充值中心", img: chargeIn, function: "recharge" },
        { title: "我的券", img: coupon, function: "" },
      ],
      orderItems: [
        { title: "我的订单", img: order },
        { title: "获奖记录", img: logo },
        { title: "地址管理", img: logo },
        { title: "常见问题", img: question },
        { title: "采购/合作", img: logo },
        { title: "观影人管理", img: logo },
        { title: "关于我们", img: about },
        { title: "身份认证", img: identify },
      ],
    };
  },
  methods: {
    toHome() {
      this.$router.go(-1);
    },
    toItems(value) {
      switch (value) {
        case "order":
          this.$router.push({ name: "RechargeCenter" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  .play-title {
    font-size: 0.9rem;
  }

  & > .nav {
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  & > .mine-top {
    background-color: #ff3366;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .mine-mess {
      display: flex;
      align-items: center;

      & > .head {
        border-radius: 100%;
        width: 3rem;
        height: 3rem;
        background-color: #bdc0c9;
        margin-right: 0.5rem;
      }

      .message {
        font-size: 0.8rem;
        color: #ffffff;
        line-height: 1.3rem;
      }
    }

    & > .swiper-left {
      padding-right: 1.5rem;
      color: #fff;
    }
  }

  & > .mine-top-second {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0;
    background-color: #fff;

    & > .play-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 33.3%;

      img {
        width: 1.6rem;
        height: 1.6rem;
        margin-bottom: 0.5rem;
      }
    }

    & > .play_item_right {
      border-right: 1px solid #e7e7e7;
    }
  }

  & > .content {
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    background-color: #fff;
    padding-left: 0.5rem;

    & > .item-list {
      display: flex;
      align-items: center;
      padding: 0.7rem 0;
      border-bottom: 1px solid #e0e0e0;

      img {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
